import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { getInitialState } from "../../utils/campaignCreation";
import { useIntl } from "react-intl";
import {
  account,
  common,
  confirm,
  errors,
  form,
  navigation,
} from "../../messages";
import FloatingActions from "../../ui/FloatingActions";
import { Add, ArrowBack, Create, Delete, MoreVert } from "@material-ui/icons";
import { DatePicker } from "../../ui/DatePicker";
import { format } from "date-fns";
import { fixDateString, parseBmarkenDate } from "../../utils/utils";
import { Autocomplete } from "@material-ui/lab";
import CommonCreateForm from "../../ui/forms/CommonCreateForm";
import CampaignDelete from "../../ui/CampaignDelete";
import { CAMPAIGN_ACTION, MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Confirm from "../../ui/Confirm";
import ReservationsConfig from "./ReservationsConfig";

const byName = (a, b) => a.name.localeCompare(b.name);

function compareDates(a, b) {
  const parts1 = a.custom_fields.startingDate.split("-");
  const parts2 = b.custom_fields.startingDate.split("-");
  const dateObj1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);
  const dateObj2 = new Date(parts2[2], parts2[1] - 1, parts2[0]);

  if (dateObj1 < dateObj2) {
    return -1;
  } else if (dateObj1 > dateObj2) {
    return 1;
  } else {
    return 0;
  }
}

function FairList({ fair, onModify, onDelete, onChangeStatus }) {
  const intl = useIntl();
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleChange = (event) => {
    onChangeStatus(fair, event.target.checked);
  };

  return (
    <Container maxWidth="md">
      <Box mb={2}>
        <Card>
          <CardHeader
            action={
              <FormControl margin="dense">
                <FormControlLabel
                  control={
                    <Switch
                      checked={fair.active}
                      onChange={handleChange}
                      color="primary"
                      key="active"
                      name="active"
                    />
                  }
                  label="Fiera attiva"
                />
              </FormControl>
            }
          />
          {fair.url && (
            <CardMedia style={{ height: "185px" }} image={fair.url} />
          )}
          <CardContent
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography gutterBottom variant="body">
              ID Fiera: {fair.id}
            </Typography>
            <Typography gutterBottom variant="body" style={{ marginTop: 10 }}>
              Non verrà più mostrata dopo il {fair.expiration_date}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              style={{ marginBottom: 25, marginTop: 15 }}
            >
              {fair.title}
            </Typography>
            {/* <Typography gutterBottom>
              {fair.description.substring(0, 300) + "..."}
            </Typography> */}
          </CardContent>
          <CardActions
            disableSpacing
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 30px",
              margin: "0 0 20px 0",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenConfirm(true)}
            >
              {intl.formatMessage(common.delete)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onModify(fair)}
            >
              {intl.formatMessage(common.modify)}
            </Button>
          </CardActions>
        </Card>
      </Box>

      {openConfirm && (
        <Confirm
          open={!!openConfirm}
          onConfirm={() => onDelete(fair)}
          onCancel={() => setOpenConfirm(false)}
          text={intl.formatMessage(confirm.deleteElement)}
          flag
        />
      )}
    </Container>
  );
}

const initialValues = (fair = {}) => ({
  title: fair.title || "",
  description: fair.description || "",
  urlHome: fair.custom_fields?.urlHome || "",
  urlMap: fair.custom_fields?.urlMap || "",
  urlDirection: fair.custom_fields?.urlDirection || "",
  urlExpositors: fair.custom_fields?.urlExpositors || "",
  location: fair.custom_fields?.location || "",
  dates: fair.custom_fields?.dates || "",
  startingDate: fair.custom_fields?.startingDate || new Date(),
  expiration: fair.expiration_date || new Date(),
  business_id: fair.business_id || "",
  id: fair.id || "",
  url: fair.url || null,
  operation_url: fair.operation_url || "",
  participant_business_ids: fair.participant_business_ids || [],
  event_campaign_id: fair.event_campaign_id || "",
});

export default function Fair() {
  const intl = useIntl();
  const history = useHistory();
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [values, setValues] = useState(initialValues());
  const [fairList, setFairList] = useState([]);
  //const [availableMerchant, setAvailableMerchant] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [events, setEvents] = useState([]);
  const [tab, setTab] = useState("general");
  const [fairBsId, setFairBsId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [eventCreate, setEventCreate] = useState(false);
  //const [eventCampaign, setEventCampaign] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [task, setTask] = useState(false);
  const [campaignValues, setCampaignValues] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [event, setEvent] = useState(null);
  //const [fairsLight, setFairsLight] = useState([]);
  //const [generalTab, setGeneralTab] = useState("fair");

  const tenant = bmapi.getUserData().tenant_id;

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const loadFairs = useCallback(() => {
    startLoading();
    if (bmapi) {
      bmapi
        .getFairs({ type: "FAIR" })
        .then((resp) => {
          if (!resp) {
            resp = [];
          } else {
            resp.map((r) => {
              r.custom_fields = JSON.parse(r.custom_fields);
              const formatDate = r.expiration_date.substring(0, 10).split("-");
              r.expiration_date = `${formatDate[2]}-${formatDate[1]}-${formatDate[0]}`;
              bmapi
                .getMerchantByType({ type: "Fair" })
                .then((resp) => {
                  //resp.unshift({ name: "Seleziona un operatore", id: "-" });
                  setValues((v) => ({ ...v, business_id: resp[0].id }));
                  setFairBsId(resp[0].id);
                  //setAvailableMerchant(resp);
                })
                .catch((e) => {
                  notifyError(getErrorMessageString(e, intl));
                });
              return r;
            });
            setFairList(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => {
    loadFairs();
    bmapi.getBusinesses().then((bs) => {
      bs.sort(byName);
      setBusinesses(bs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadFairs]);

  const loadEvents = () => {
    startLoading();

    bmapi
      .getEventsByCampaign(values.event_campaign_id)
      .then((resp) => {
        setEvents(resp);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  const handleValue = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target && label !== "participant_business_ids")
        updateValue(i.target.value);
      else if (i?.target && label === "participant_business_ids")
        updateValue(f.map((bs) => bs.id));
      //updateValue(f);
      else updateValue(i);
    };
  }, []);

  const handleInputFile = ({ target }) => {
    const file = target.files[0];
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      setValues({
        ...values,
        url: file,
        url_preview: e.target.result,
      });
    };
  };

  const handleClose = () => {
    setOpenDialog(false);
    setValues({ ...initialValues(), business_id: fairBsId });
    setTab("general");
    setEventCreate(false);
    setEvents([]);
    setEvent(null);
    setCampaignValues(getInitialState("EVENT_PASS", bmapi));
    //setEventCampaign(false);
  };

  const handleTabs = (v) => {
    if (!dirty || (dirty && tab === "general")) {
      setTab(v);
      setEvent(null);
      setEventCreate(false);
      setCampaignValues(getInitialState("EVENT_PASS", bmapi));
    }
    if (v !== "events" && tab === "events" && dirty) {
      const res = window.confirm(intl.formatMessage(confirm.tabPrompt));
      if (res === true) {
        setTab(v);
        setEvent(null);
        setEventCreate(false);
        setCampaignValues(getInitialState("EVENT_PASS", bmapi));
      } else return;
    }
    setDirty(false);
    if (v === "events" && values.event_campaign_id) {
      loadEvents();
    }
  };

  const handleMenu = (e, event) => {
    setAnchorEl(e.currentTarget);
    setEvent(event);
  };

  const formatCustomFields = (fields) => {
    const customFields = {
      location: fields.location,
      dates: fields.dates,
      startingDate: format(fields.startingDate, "dd-MM-yyyy"),
      urlExpositors: fields.urlExpositors,
      urlHome: fields.urlHome,
      urlMap: fields.urlMap,
      urlDirection: fields.urlDirection,
    };

    return JSON.stringify(customFields);
  };

  const submit = (e) => {
    e.preventDefault();

    const fair = {
      ...values,
      custom_fields: formatCustomFields(values),
      expiration: format(values.expiration, "yyyy-MM-dd"),
      business_id: values.business_id !== "-" ? values.business_id : "",
    };

    startLoading();

    if (!fair.id || (fair.id && typeof fair.url === "object")) {
      let formData = new FormData();
      formData.append("file", fair.url);
      formData.append("id", fair.id);
      formData.append("business_id", fair.business_id);
      formData.append("type", "FAIR");
      formData.append("priority", "5");
      formData.append("title", fair.title);
      formData.append("custom_fields", fair.custom_fields);
      formData.append("description", fair.description);
      formData.append("expiration", fair.expiration);
      formData.append(
        "participant_business_ids",
        fair.participant_business_ids
      );
      formData.append("operation_url", fair.operation_url);

      bmapi
        .createFair(formData, tenant)
        .then(() => {
          handleClose();
          notifySuccess(intl.formatMessage(account.saveConfirm));
        })
        .then(() => {
          loadFairs();
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      bmapi
        .updateFair(
          fair.id,
          fair.title,
          fair.description,
          fair.expiration,
          "5",
          "FAIR",
          fair.custom_fields,
          fair.business_id,
          fair.participant_business_ids,
          fair.operation_url,
          tenant
        )
        .then(() => {
          handleClose();
          notifySuccess(intl.formatMessage(account.saveConfirm));
        })
        .then(() => {
          loadFairs();
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
    stopLoading();
  };

  const onModify = (fair) => {
    let newFair = {
      ...fair,
      custom_fields: {
        ...fair.custom_fields,
        startingDate: fixDateString(fair.custom_fields.startingDate),
      },
      expiration_date: fixDateString(fair.expiration_date),
    };

    setOpenDialog(true);
    setValues(initialValues(newFair));
    setCampaignValues(getInitialState("EVENT_PASS", bmapi));
  };

  const onDelete = (fair) => {
    startLoading();
    bmapi
      .deleteFair(fair.id)
      .then(() => {
        loadFairs();
        notifySuccess(intl.formatMessage(common.deleteElement));
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    stopLoading();
  };

  const onChangeStatus = (fair, checked) => {
    startLoading();
    bmapi
      .updateFairStatus(fair.id, checked)
      .then(() => {
        loadFairs();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    stopLoading();
  };

  /*const firstEvent = () => {
    startLoading();
    bmapi
      .createFairCampaigns(values.id)
      .then((resp) => {
        //setEventCampaign(resp.event_campaign_id);
        setValues({ ...values, event_campaign_id: resp.event_campaign_id });
        setEventCreate(true);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    stopLoading();
  };*/

  const createCampaign = (after, v, resp, onModify) => {
    return (onModify
      ? bmapi.saveCampaign(v, event.id)
      : bmapi.saveCampaign({
          ...v,
          main_event_id: resp?.event_campaign_id || values.event_campaign_id,
        })
    )
      .then(after)
      .then(() => {
        onModify
          ? intl.formatMessage({
              id: "pages.campaignEdit.saved",
              defaultMessage: "Campagna modificata con successo",
            })
          : notifySuccess("Evento creato con successo");
        handleClose();
        loadFairs();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
        if (
          getErrorMessageString(e, intl).includes(
            "[VTENEX synchronization ERROR]: response error - code: 500"
          )
        ) {
          notifyError(intl.formatMessage(errors.syncCRM));
          setDirty(false);
        }
      })
      .finally(() => stopLoading());
  };

  const submitEvent = (v, after) => {
    startLoading();

    v = {
      ...v,
      expiration_date: v.start_date,
      product_limit_date: v.start_date,
      business_id: values.business_id,
      allow_user_issue: true,
      primary_product_not_mandatory: true,
      loop: false,
    };

    if (!event && !values.event_campaign_id) {
      // first event
      return bmapi
        .createFairCampaigns(values.id)
        .then((resp) => {
          setValues({ ...values, event_campaign_id: resp.event_campaign_id });
          createCampaign(after, v, resp);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else if (!event) {
      return createCampaign(after, v);
    } else if (event) {
      // on modify event
      v = {
        ...v,
        main_event_id: values.event_campaign_id,
      };
      return createCampaign(after, v, false, "modify");
    }
  };

  const editEvent = () => {
    setEventCreate(true);
    setCampaignValues(
      getInitialState(false, bmapi, false, {
        ...event,
        start_date: new Date(event.start_date),
        expiration_date: new Date(event.expiration_date),
        tags: event.tags || [],
      })
    );
  };

  const endTask = () => {
    setTask(false);
    setEvent(null);
  };

  const after = () => {
    endTask();
    loadEvents();
  };

  return (
    <Container maxWidth="sm">
      <Card>
        <Dialog open={openDialog} fullWidth maxWidth="sm">
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            onChange={(_, v) => {
              handleTabs(v);
            }}
          >
            <Tab
              label={intl.formatMessage(common.generalData)}
              value="general"
            />
            {values.id && (
              <Tab
                label={intl.formatMessage(navigation.events)}
                value="events"
              />
            )}
            {/*{values.id && <Tab label="Configurazioni" value="conf" />}*/}
          </Tabs>
          {tab === "general" && (
            <form onSubmit={submit}>
              <DialogContent>
                {!values.url ? (
                  "Carica un'immagine per vederne qui l'anteprima. Infine premi conferma per creare il contenuto"
                ) : (
                  <CardMedia
                    image={values.url_preview || values.url}
                    style={{
                      height: "200px",
                      width: "200px",
                      margin: "0 auto",
                    }}
                  />
                )}
                <TextField
                  margin="dense"
                  label={intl.formatMessage(form.title)}
                  value={values.title}
                  onChange={handleValue("title")}
                  fullWidth
                  required
                />
                {/*  <TextField
                  margin="dense"
                  label={intl.formatMessage(form.description)}
                  value={values.description}
                  onChange={handleValue("description")}
                  fullWidth
                />*/}

                {/* <TextField
                  margin="dense"
                  label="Operatore"
                  value={values.business_id}
                  onChange={handleValue("business_id")}
                  fullWidth
                  select
                >
                  {availableMerchant?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.manager_email
                        ? option.manager_email + " (" + option.name + ")"
                        : option.name}
                    </MenuItem>
                  ))}
                </TextField>*/}

                <Autocomplete
                  multiple
                  margin="normal"
                  id="tags-standard"
                  options={businesses}
                  getOptionLabel={(option) => option.name}
                  onChange={handleValue("participant_business_ids")}
                  value={businesses.filter((bs) =>
                    values.participant_business_ids?.includes(bs.id)
                  )}
                  //value={values.participant_business_ids}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Elenco degli espositori"
                      name="participant_business_ids"
                      value={values.participant_business_ids}
                    />
                  )}
                />
                <TextField
                  margin="dense"
                  label="URL home fiera"
                  value={values.urlHome}
                  onChange={handleValue("urlHome")}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  label="URL mappa fiera"
                  value={values.urlMap}
                  onChange={handleValue("urlMap")}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  label="URL come raggiungere fiera"
                  value={values.urlDirection}
                  onChange={handleValue("urlDirection")}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  label="URL lista espositori"
                  value={values.urlExpositors}
                  onChange={handleValue("urlExpositors")}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  label="Indirizzo di registrazione confermata"
                  value={values.operation_url}
                  onChange={handleValue("operation_url")}
                  fullWidth
                />
                {/*   <TextField
                  margin="dense"
                  label="Luogo"
                  value={values.location}
                  onChange={handleValue("location")}
                  fullWidth
                />*/}

                {/*  <TextField
                  margin="dense"
                  label="Quando"
                  value={values.dates}
                  onChange={handleValue("dates")}
                  fullWidth
                /> */}

                <DatePicker
                  label="Valida dal"
                  value={values.startingDate}
                  onChange={handleValue("startingDate")}
                  fullWidth
                  margin="normal"
                  inputVariant="outlined"
                  clearable
                  //minDate={id ? values.start_at : null}
                  //clearable={!id}
                />
                <DatePicker
                  label="Valida fino al"
                  value={values.expiration}
                  onChange={handleValue("expiration")}
                  fullWidth
                  margin="normal"
                  inputVariant="outlined"
                  clearable
                  //minDate={id ? values.start_at : null}
                  //clearable={!id}
                />
              </DialogContent>
              <DialogActions>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-photo"
                  onChange={handleInputFile}
                  type="file"
                />
                <Button type="button" variant="outlined" color="secondary">
                  <label htmlFor="icon-button-photo">Seleziona Immagine</label>
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Annulla
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!values.url || !values.title}
                >
                  Conferma
                </Button>
              </DialogActions>
            </form>
          )}
          {tab === "events" && (
            <React.Fragment>
              {eventCreate ? (
                <React.Fragment>
                  <CommonCreateForm
                    initialValues={campaignValues}
                    handleSubmit={submitEvent}
                    campaign={event}
                    dirty={dirty}
                    setDirty={setDirty}
                    fair
                  />
                  <FormControl margin="normal" fullWidth>
                    <Button onClick={handleClose} startIcon={<ArrowBack />}>
                      Torna alle fiere
                    </Button>
                  </FormControl>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <DialogContent>
                    {events.length ? (
                      events.map((ev) => (
                        <List key={ev.id}>
                          <ListItem>
                            <ListItemText
                              primary={ev.name}
                              secondary={
                                format(
                                  parseBmarkenDate(ev.start_date),
                                  "dd/MM/yy"
                                ) +
                                " " +
                                ev.start_hour +
                                "-" +
                                ev.end_hour
                              }
                            />
                          </ListItem>
                          <ListItemSecondaryAction>
                            <IconButton onClick={(e) => handleMenu(e, ev)}>
                              <MoreVert />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </List>
                      ))
                    ) : (
                      <Typography>
                        Non ci sono ancora eventi per questa fiera.
                      </Typography>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      color="primary"
                    >
                      {intl.formatMessage(common.close)}
                    </Button>
                    <Button
                      /*onClick={() =>
                        !values.event_campaign_id
                          ? firstEvent()
                          : setEventCreate(true)
                      }*/
                      onClick={() => setEventCreate(true)}
                      variant="contained"
                      color="primary"
                    >
                      Crea Evento
                    </Button>
                  </DialogActions>
                </React.Fragment>
              )}
              <Menu
                key={event}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => {
                  setAnchorEl(null);
                  setEvent(null);
                }}
                keepMounted
                onClick={() => setAnchorEl(null)}
              >
                <MenuItem onClick={editEvent}>
                  <ListItemIcon>
                    <Create />
                  </ListItemIcon>
                  <Typography>{intl.formatMessage(common.modify)}</Typography>
                </MenuItem>
                <MenuItem onClick={() => setTask(CAMPAIGN_ACTION.DELETE)}>
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  <Typography>{intl.formatMessage(common.delete)}</Typography>
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
          {tab === "conf" && (
            <React.Fragment>
              <ReservationsConfig
                tabFair
                bsFair={values.business_id}
                fairId={values.id}
                handleClose={handleClose}
              />
            </React.Fragment>
          )}
        </Dialog>
      </Card>

      {fairList &&
        (!fairList.length ? (
          <CardContent>
            <Typography gutterBottom>Non ci sono fiere</Typography>
          </CardContent>
        ) : (
          <React.Fragment>
            <Box my={2}>
              <Button onClick={goToHome} startIcon={<ArrowBack />}>
                {intl.formatMessage(common.backHome)}
              </Button>
            </Box>
            {/*  <Box mb={2}>
              <Tabs
                value={generalTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                onChange={(_, v) => {
                  setGeneralTab(v);
                }}
              >
                <Tab label="Fiere" value="fair" />
                <Tab label="Configurazione" value="conf" />
              </Tabs>
            </Box>*/}

            <React.Fragment>
              <List component="div" disablePadding>
                {fairList
                  .filter((fair) => fair.active)
                  .sort(compareDates)
                  .map((f) => (
                    <React.Fragment key={f.id}>
                      <FairList
                        onModify={onModify}
                        fair={f}
                        onDelete={onDelete}
                        onChangeStatus={onChangeStatus}
                      />
                    </React.Fragment>
                  ))}
              </List>
              <List component="div" disablePadding style={{ marginTop: 30 }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ textAlign: "center" }}
                >
                  Fiere concluse
                </Typography>
                {fairList
                  .filter((fair) => !fair.active)
                  .sort(compareDates)
                  .map((f) => (
                    <React.Fragment key={f.id}>
                      <FairList
                        onModify={onModify}
                        fair={f}
                        onDelete={onDelete}
                        onChangeStatus={onChangeStatus}
                      />
                    </React.Fragment>
                  ))}
              </List>
              <FloatingActions>
                <Fab
                  variant="extended"
                  color="primary"
                  onClick={() => setOpenDialog(true)}
                >
                  <Add />
                  {`${intl.formatMessage(common.create)} fiera`}
                </Fab>
              </FloatingActions>
            </React.Fragment>
          </React.Fragment>
        ))}

      {task === CAMPAIGN_ACTION.DELETE && (
        <CampaignDelete
          campaign={event}
          open={task === CAMPAIGN_ACTION.DELETE}
          onSuccess={after}
          onCancel={endTask}
        />
      )}

      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>
    </Container>
  );
}
