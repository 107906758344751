import { useState, useCallback } from "react";
import { endOfDay, startOfDay } from "date-fns";

import {
  CardGiftcard,
  ConfirmationNumber,
  CreditCard,
  Event,
  LocalPlay,
  Loyalty,
  Stars,
} from "@material-ui/icons";

import { useBmapi } from "./bmapi-context";
import {
  BUSINESS_TYPES,
  CAMPAIGN_STATUS,
  CAMPAIGNS_LABELS,
  CONSUMER_ROUTES,
  FEATURES,
  PRODUCT_SUBTYPES,
  PRODUCT_TYPES,
  PRODUCTS,
  ROLES,
  SKINS,
} from "./constants";

function alphasort(a, b) {
  return a.name.localeCompare(b.name);
}

export const IconsMap = {
  [PRODUCT_SUBTYPES.COUPON_DISCOUNT]: ConfirmationNumber,
  [PRODUCT_SUBTYPES.COUPON_SIMPLE]: ConfirmationNumber,
  [PRODUCT_SUBTYPES.COUPON_VALUE]: ConfirmationNumber,
  [PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE]: Loyalty,
  [PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN]: LocalPlay,
  [PRODUCT_SUBTYPES.MULTIWIN_COUPON]: Stars,
  [PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE]: Event,
  [PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE]: CardGiftcard,
  [PRODUCT_SUBTYPES.PROVISIONING_CARD]: CreditCard,
};

export const subtypeMap = {
  [PRODUCTS.COUPON_DISCOUNT_PERC]: PRODUCT_SUBTYPES.COUPON_DISCOUNT,
  [PRODUCTS.COUPON_MULTISHOT]: PRODUCT_SUBTYPES.COUPON_SIMPLE,
  [PRODUCTS.COUPON_SIMPLE]: PRODUCT_SUBTYPES.COUPON_SIMPLE,
  [PRODUCTS.COUPON_DISCOUNT_VALUE]: PRODUCT_SUBTYPES.COUPON_VALUE,
  [PRODUCTS.COURSE_PASS]: PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
  [PRODUCTS.EARNING_CARD]: PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE,
  [PRODUCTS.EVENT_PASS]: PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
  [PRODUCTS.INSTANT_WIN]: PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN,
  [PRODUCTS.MULTI_WIN]: PRODUCT_SUBTYPES.MULTIWIN_COUPON,
  [PRODUCTS.SHOPPING_CARD]: PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE,
  [PRODUCTS.PROVISIONING_CARD]: PRODUCT_SUBTYPES.PROVISIONING_CARD,
};

export const typeMap = {
  [PRODUCT_SUBTYPES.COUPON_DISCOUNT]: PRODUCT_TYPES.CAMPAIGN_COUPON,
  [PRODUCT_SUBTYPES.COUPON_SIMPLE]: PRODUCT_TYPES.CAMPAIGN_COUPON,
  [PRODUCT_SUBTYPES.COUPON_VALUE]: PRODUCT_TYPES.CAMPAIGN_COUPON,
  [PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE]: PRODUCT_TYPES.CAMPAIGN_EARNING_CARD,
  [PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN]:
    PRODUCT_TYPES.CAMPAIGN_EARNING_CARD,
  [PRODUCT_SUBTYPES.MULTIWIN_COUPON]: PRODUCT_TYPES.CAMPAIGN_MULTIWIN,
  [PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE]: PRODUCT_TYPES.CAMPAIGN_EVENT_PASS,
  [PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE]: PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD,
  [PRODUCT_SUBTYPES.PROVISIONING_CARD]: PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD,
};

export function getLabel(subtype) {
  return (
    {
      [PRODUCT_SUBTYPES.COUPON_DISCOUNT]: CAMPAIGNS_LABELS.COUPON,
      [PRODUCT_SUBTYPES.MULTIWIN_COUPON]: CAMPAIGNS_LABELS.COUPON,
      [PRODUCT_SUBTYPES.COUPON_SIMPLE]: CAMPAIGNS_LABELS.COUPON,
      [PRODUCT_SUBTYPES.COUPON_VALUE]: CAMPAIGNS_LABELS.COUPON,
      [PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE]: CAMPAIGNS_LABELS.CARD,
      [PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN]: CAMPAIGNS_LABELS.INSTANT,
      [PRODUCT_SUBTYPES.MULTIWIN_COUPON]: CAMPAIGNS_LABELS.INSTANT,
      [PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE]: CAMPAIGNS_LABELS.PASS,
      [PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE]: CAMPAIGNS_LABELS.CARD,
      [PRODUCT_SUBTYPES.PROVISIONING_CARD]: CAMPAIGNS_LABELS.SUBSCRIPTION,
    }[subtype] || CAMPAIGNS_LABELS.CARD
  );
}

export function getAvailability({ campaign, statistics }) {
  switch (campaign.rules.subtype) {
    case PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN:
      return Math.floor(
        (statistics.received_value - statistics.used_value) /
          campaign.rules.instant_win_threshold
      );

    case PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE:
    case PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE:
    case PRODUCT_SUBTYPES.PROVISIONING_CARD:
      return (
        (statistics.received_value -
          statistics.used_value -
          statistics.expired_value -
          statistics.issued_value) /
        100
      );

    case PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE:
    case PRODUCT_SUBTYPES.COUPON_DISCOUNT:
    case PRODUCT_SUBTYPES.COUPON_SIMPLE:
    case PRODUCT_SUBTYPES.COUPON_VALUE:
      if (campaign.rules.shot_number === -1) {
        return (
          statistics.received_qty -
          statistics.expired_qty -
          statistics.issued_qty
        );
      }
      return (
        statistics.received_qty * (campaign.rules.shot_number || 1) -
        statistics.used_qty -
        statistics.expired_qty -
        statistics.issued_qty
      );

    default:
      break;
  }

  return false;
}

export function useCampaigns() {
  const { bmapi, baseUrl, businessId, startLoading, stopLoading } = useBmapi();
  const [campaigns, setCampaigns] = useState(null);

  const loadCampaigns = useCallback(async () => {
    startLoading();

    const ownCampaign = (campaign) =>
      bmapi.isTenantManager() ||
      bmapi.getUserInfo().business.id === campaign.business_owner_id;

    const canIssue = (campaign) =>
      ownCampaign(campaign) &&
      bmapi.can(FEATURES.ISSUE_PRODUCT) &&
      !campaign.isExpired &&
      bmapi.hasExceptions();

    const canUnjoin = (campaign, toSign) => {
      return (
        bmapi.isTenantManager() &&
        bmapi.getUserInfo().business.type === BUSINESS_TYPES.MERCHANT &&
        bmapi.getUserInfo().business.id !== campaign.business_owner_id &&
        !toSign
      );
    };

    const getSharableLink = (campaign) =>
      `${baseUrl}${CONSUMER_ROUTES.CAMPAIGN.replace(
        ":campaignId",
        campaign.campaign_id
      ).slice(1)}`;

    return Promise.all([
      businessId ? bmapi.getTerms() : [],
      businessId ? bmapi.getCampaigns() : [],
    ])
      .then(([terms, cs = []]) => {
        const campaigns = (cs || [])
          .filter((campaign) => {
            return !(
              bmapi.settings.skin === SKINS.EDUCATION &&
              bmapi.getUserInfo().role === ROLES.STORE_MANAGER &&
              campaign.campaign_data.front_end_type === PRODUCTS.COURSE_PASS &&
              campaign.campaign_data.rules.main_event_id === ""
            );
          })
          .sort(alphasort)
          .map((campaign) => {
            const campaignTerm = terms.find(
              (c) => c.campaign_id === campaign.campaign_id
            );

            const isStarted =
              new Date() > startOfDay(new Date(campaign.start_date));

            const isExpired =
              campaign.status === CAMPAIGN_STATUS.EXPIRED ||
              new Date() > endOfDay(new Date(campaign.expiration_date));

            const toSign = campaignTerm && !campaignTerm.signed;

            return {
              ...campaign,
              campaign_data: campaign.campaign_data || campaign.CampaignData, // Legacy API
              terms: campaignTerm || false,
              toSign,
              canUnjoin: canUnjoin(campaign, toSign),
              canIssue: canIssue(campaign),
              ownCampaign: ownCampaign(campaign),
              shareLink: getSharableLink(campaign),
              isActive: !toSign && isStarted && !isExpired,
              isFuture: !toSign && !isStarted,
              isAvailable: toSign && !isExpired,
              isExpired: !toSign && isExpired,
            };
          });
        setCampaigns(campaigns);
        return campaigns;
      })
      .finally(stopLoading);
  }, [baseUrl, bmapi, businessId, startLoading, stopLoading]);

  const signCampaign = useCallback(
    (campaign) => bmapi.signTerm(campaign.terms.id),
    [bmapi]
  );

  return { campaigns, loadCampaigns, signCampaign };
}
